import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import styles from './Consultation.module.scss';
import { theme } from '../../../store/theme';
import Img from '../../../components/Img';
import AnimatedTitle from '../../../components/AnimatedTitle';

interface IAltView {
  title: string,
  description: string,
  inputValue: string,
  handleChange: (value: string) => void;
  submitMessage: string;
  submitMessageStyle: 'error' | 'success';
  className?: string;
  handleSubmit: () => void;
  formId?: string;
  isLoading: boolean;
  isEmailValid: boolean;
}

const AltView = ({
  title,
  description,
  inputValue, handleChange,
  submitMessage,
  submitMessageStyle,
  className,
  handleSubmit,
  formId,
  isLoading,
  isEmailValid,
}: IAltView) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={classNames(styles.altViewWrapper, 'container')}>
      <div className={styles.altContentWrapper}>
        <div className={styles.altContent}>
          <h2 className={classNames(styles.title, 'title3')}><AnimatedTitle title={title} /></h2>
          <p className={styles.description}>{description}</p>
          <form
            className={classNames(styles.form, className)}
            id={formId}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div
              className={styles.inputWrap}
            >
              <input
                type='email'
                placeholder='Enter your email'
                className={styles.input}
                value={inputValue}
                onChange={e => handleChange(e.target.value)}
              />
              <button
                type='submit'
                className={styles.submitButton}
                aria-label='Get consultation'
                disabled={isLoading || !isEmailValid}
              >
                {isLoading ? (
                  <img
                    className={styles.spinner}
                    src={`/media/spinner_${appTheme}.webp`}
                    alt='spinner'
                  />
                ) : (
                  <svg>
                    <use
                      xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                      href='/media/arrow-right-bold.svg#arrowRight'
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className={styles.line}>
              <Img
                src={`/media/consultation/consultationLine_${appTheme}.svg`}
                alt='consultation line'
              />
            </div>
            {(submitMessage) && (
              <p className={classNames(styles.status, styles[submitMessageStyle])}>
                {submitMessage}
              </p>
            )}
          </form>
          <svg className={styles.planeIcon}>
            <use
              href={`media/consultation/paper_plane_${appTheme}.svg#paper-plane-${appTheme}`}
              xlinkHref={`media/consultation/paper_plane_${appTheme}.svg#paper-plane-${appTheme}`}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AltView;
